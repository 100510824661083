<template>
  <div class="table-container">
    <ModalTable ref="tableRef">
      <table class="table-hoverr modal-table">
        <div class="toolbar">
          <div class="right">
            <div class="watch">
              <WatchSvg />
              <span>دیدبان</span>
            </div>
            <div class="type">
              <TypeSvg />
              <span>نوع محصول</span>
              :
              <span class="bold">اصلی</span>
            </div>
            <div class="money">
              <MoneySvg />
              <span>نوع ارز</span>
              :
              <span class="bold">تومان</span>
            </div>
          </div>
          <div class="left">
            <div class="search">
              <SearchSvg />
              <input
                placeholder="محصول موردنظر خود را جستجو کنید ..."
                v-model="searchQuery"
                v-on:input="searchFunction"
              />
            </div>
          </div>
        </div>
        <thead>
          <tr>
            <th class="bookmark">
              <div @click="tableRef.closeModal()"><UnExpandSvg /></div>
            </th>
            <th class="number">#</th>
            <th class="name">نام محصول</th>
            <th class="price">قیمت‌ بازار‌</th>
            <th class="price">قیمت‌ بورس</th>
            <th class="price">قیمت‌ تمام شده</th>
            <th class="date">تاریخ</th>
            <th class="change">آخرین تغییرات</th>
            <th class="chart">نمودار هفتگی</th>
            <th class="link"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in rows" v-bind:key="item">
            <td class="starSvg">
              <div>
                <input
                  class="form-check-input mt-0"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                />
              </div>
            </td>
            <td>
              <span>
                {{ index + 1 }}
              </span>
            </td>
            <td class="body-name">
              <!-- <span class="table-name" v-if="item.icon?.length > 10"
                ><img :src="`data:image/png;base64,${item.icon}`"
              /></span> -->
              <span>
                {{ item.name }}
              </span>
            </td>
            <td>
              <span v-if="item.hidePrice === 0">
                <span
                  class="priceup"
                  v-if="compareLastTwoValues(item.prices) && item.prices"
                >
                  {{ EnToPr(item.latestPrice) }}</span
                >
                <span
                  class="pricedown"
                  v-if="!compareLastTwoValues(item.prices) && item.prices"
                >
                  {{ EnToPr(item.latestPrice) }}</span
                >
              </span>
              <span class="call-button" v-if="item.hidePrice === 1">
                <button class="btn btn-primary btn-sm">تماس بگیرید</button>
              </span>
            </td>
            <td>
              <span v-if="item.hidePrice === 0">
                {{ EnToPr(item.latestboorsPrice) }}
              </span>
              <span class="call-button" v-if="item.hidePrice === 1">
                <button class="btn btn-primary btn-sm">تماس بگیرید</button>
              </span>
            </td>
            <td>
              <span v-if="item.hidePrice === 0">
                {{ EnToPr(item.latestAllPrice) }}
              </span>
              <span class="call-button" v-if="item.hidePrice === 1">
                <button class="btn btn-primary btn-sm">تماس بگیرید</button>
              </span>
            </td>
            <td>
              <span> {{ EnToPrNoDate(item.latestCreatedOn) }} </span>
            </td>
            <td>
              <div
                class="priceup"
                v-if="compareLastTwoValues(item.prices) && item.prices"
              >
                <span>
                  {{ EnToPrNo(getPercent(item.prices)) }}
                </span>
                <UpSvg />
              </div>
              <div
                class="pricedown"
                v-if="!compareLastTwoValues(item.prices) && item.prices"
              >
                <span>
                  {{ EnToPrNo(getPercent(item.prices)) }}
                </span>
                <DownSvg />
              </div>
            </td>

            <td
              v-if="
                compareLastTwoValues(item.prices) &&
                item.prices &&
                JSON.parse(item.prices).length > 1
              "
              class="chart-container green"
            >
              <TrendChart :datasets="[{ data: JSON.parse(item.prices) }]" />
            </td>
            <td
              v-if="
                !compareLastTwoValues(item.prices) &&
                item.prices &&
                JSON.parse(item.prices)?.length > 1
              "
              class="chart-container"
            >
              <TrendChart :datasets="[{ data: JSON.parse(item.prices) }]" />
            </td>
            <td
              v-if="!item.prices || JSON.parse(item.prices)?.length < 1"
              class="chart-container"
            ></td>
            <td class="link">
              اطلاعات بیشتر
              <span><LeftArrowSvg /></span>
            </td>
          </tr>
        </tbody></table
    ></ModalTable>

    <table class="table-hoverr">
      <div class="toolbar">
        <div class="right">
          <div class="watch">
            <WatchSvg />
            <span>دیدبان</span>
          </div>
          <div class="type">
            <TypeSvg />
            <span>نوع محصول</span>
            :
            <span class="bold">اصلی</span>
          </div>
          <div class="money">
            <MoneySvg />
            <span>نوع ارز</span>
            :
            <span class="bold">تومان</span>
          </div>
        </div>
        <div class="left">
          <div class="search">
            <SearchSvg />
            <input
              placeholder="محصول موردنظر خود را جستجو کنید ..."
              v-model="searchQuery"
              v-on:input="searchFunction"
            />
          </div>
        </div>
      </div>
      <thead>
        <tr>
          <th class="bookmark">
            <div @click="tableRef.openModal()"><ExpandSvg /></div>
          </th>
          <th class="number">#</th>
          <th class="name">نام محصول</th>
          <th class="price">قیمت‌ بازار‌</th>
          <th class="price">قیمت‌ بورس</th>
          <th class="price">قیمت‌ تمام شده</th>
          <th class="date">تاریخ</th>
          <th class="change">آخرین تغییرات</th>
          <th class="chart">نمودار هفتگی</th>
          <th class="link"></th>
        </tr>
      </thead>
      <div
        class="spinner-border text-primary"
        role="status"
        v-if="loading"
      ></div>
      <tbody v-if="!loading">
        <tr v-for="(item, index) in rows" v-bind:key="item">
          <td class="starSvg">
            <div>
              <input
                class="form-check-input mt-0"
                type="checkbox"
                value=""
                aria-label="Checkbox for following text input"
              />
            </div>
          </td>
          <td>
            <span>
              {{ index + 1 }}
            </span>
          </td>
          <td class="body-name">
            <!-- <span class="table-name" v-if="item.icon?.length > 10"
              ><img :src="`data:image/png;base64,${item.icon}`"
            /></span> -->
            <span>
              {{ item.name }}
            </span>
          </td>
          <td>
            <span v-if="item.hidePrice === 0">
              <span
                class="priceup"
                v-if="compareLastTwoValues(item.prices) && item.prices"
              >
                {{ EnToPr(item.latestPrice) }}</span
              >
              <span
                class="pricedown"
                v-if="!compareLastTwoValues(item.prices) && item.prices"
              >
                {{ EnToPr(item.latestPrice) }}</span
              >
            </span>
            <span class="call-button" v-if="item.hidePrice === 1">
              <button class="btn btn-primary btn-sm">تماس بگیرید</button>
            </span>
          </td>
          <td>
            <span v-if="item.hidePrice === 0">
              {{ EnToPr(item.latestboorsPrice) }}
            </span>
            <span class="call-button" v-if="item.hidePrice === 1">
              <button class="btn btn-primary btn-sm">تماس بگیرید</button>
            </span>
          </td>
          <td>
            <span v-if="item.hidePrice === 0">
              {{ EnToPr(item.latestAllPrice) }}
            </span>
            <span class="call-button" v-if="item.hidePrice === 1">
              <button class="btn btn-primary btn-sm">تماس بگیرید</button>
            </span>
          </td>
          <td>
            <span> {{ EnToPrNoDate(item.latestCreatedOn) }} </span>
          </td>
          <td>
            <div
              class="priceup"
              v-if="compareLastTwoValues(item.prices) && item.prices"
            >
              <span>
                {{ EnToPrNo(getPercent(item.prices)) }}
              </span>
              <UpSvg />
            </div>
            <div
              class="pricedown"
              v-if="!compareLastTwoValues(item.prices) && item.prices"
            >
              <span>
                {{ EnToPrNo(getPercent(item.prices)) }}
              </span>
              <DownSvg />
            </div>
          </td>

          <td
            v-if="
              compareLastTwoValues(item.prices) &&
              item.prices &&
              JSON.parse(item.prices).length > 1
            "
            class="chart-container green"
          >
            <TrendChart :datasets="[{ data: JSON.parse(item.prices) }]" />
          </td>
          <td
            v-if="
              !compareLastTwoValues(item.prices) &&
              item.prices &&
              JSON.parse(item.prices)?.length > 1
            "
            class="chart-container"
          >
            <TrendChart :datasets="[{ data: JSON.parse(item.prices) }]" />
          </td>
          <td
            v-if="!item.prices || JSON.parse(item.prices)?.length < 1"
            class="chart-container"
          ></td>
          <td class="link">
            اطلاعات بیشتر
            <span><LeftArrowSvg /></span>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- /////////////////////////////// -->
    <!-- /////////////mobile/////////// -->
    <!-- ///////////////////////////// -->
    <table class="table-hoverr mobile">
      <div class="toolbar">
        <div class="right">
          <div class="watch">
            <WatchSvg />
            <span>دیدبان</span>
          </div>
          <div class="type">
            <TypeSvg />
            <span>نوع محصول</span>
            :
            <span class="bold">اصلی</span>
          </div>
          <div class="money">
            <MoneySvg />
            <span>نوع ارز</span>
            :
            <span class="bold">تومان</span>
          </div>
        </div>
        <div class="left">
          <div class="search">
            <SearchSvg />
            <input placeholder="محصول موردنظر خود را جستجو کنید ..." />
          </div>
        </div>
      </div>
      <thead>
        <tr>
          <th class="bookmark"></th>
          <th class="number">#</th>
          <th class="name">نام محصول</th>
          <th class="price">قیمت‌ بازار‌</th>
          <th class="link"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, idx) in rows" v-bind:key="item.id">
          <tr :class="expandedRow === idx && 'color-row'">
            <td class="starSvg">
              <div>
                <input class="form-check-input mt-0" type="checkbox" />
              </div>
            </td>
            <td>
              <span>
                {{ idx + 1 }}
              </span>
            </td>
            <td class="body-name">
              <span>
                {{ item.name }}
              </span>
            </td>
            <td>
              <span v-if="item.hidePrice === 0">
                <span
                  class="priceup"
                  v-if="compareLastTwoValues(item.prices) && item.prices"
                >
                  {{ EnToPr(item.latestPrice) }}</span
                >
                <span
                  class="pricedown"
                  v-if="!compareLastTwoValues(item.prices) && item.prices"
                >
                  {{ EnToPr(item.latestPrice) }}</span
                >
              </span>
              <span class="call-button" v-if="item.hidePrice === 1">
                <button class="btn btn-primary btn-sm">تماس بگیرید</button>
              </span>
            </td>

            <td @click="toggleRow(idx)">
              <div class="link" v-if="expandedRow === idx">
                <span class="flip"><ArrowDown /></span>
                مخفی کردن
              </div>
              <div class="link" v-if="expandedRow !== idx">
                سایر اطلاعات
                <span><ArrowDown /></span>
              </div>
            </td>
          </tr>
          <tr
            :class="
              expandedRow === idx ? 'details-row show' : 'details-row hide'
            "
          >
            <td colspan="5">
              <div class="details">
                <div class="right">
                  <div class="item">
                    <span class="header">قیمت بورس</span>
                    <span v-if="item.hidePrice === 0">
                      {{ EnToPr(item.latestboorsPrice) }}
                    </span>
                    <span class="call-button" v-if="item.hidePrice === 1">
                      <button class="btn btn-primary btn-sm">
                        تماس بگیرید
                      </button>
                    </span>
                  </div>
                  <div class="item">
                    <span class="header">قیمت تمام شده</span>
                    <span v-if="item.hidePrice === 0">
                      {{ EnToPr(item.latestAllPrice) }}
                    </span>
                    <span class="call-button" v-if="item.hidePrice === 1">
                      <button class="btn btn-primary btn-sm">
                        تماس بگیرید
                      </button>
                    </span>
                  </div>
                  <div class="item">
                    <span class="header">تاریخ</span>
                    <span> {{ EnToPrNoDate(item.latestCreatedOn) }} </span>
                  </div>
                  <div class="item">
                    <span class="header">آخرین تغییرات</span>
                    <div
                      class="priceup"
                      v-if="compareLastTwoValues(item.prices) && item.prices"
                    >
                      <span>
                        {{ EnToPrNo(getPercent(item.prices)) }}
                      </span>
                      <UpSvg />
                    </div>
                    <div
                      class="pricedown"
                      v-if="!compareLastTwoValues(item.prices) && item.prices"
                    >
                      <span>
                        {{ EnToPrNo(getPercent(item.prices)) }}
                      </span>
                      <DownSvg />
                    </div>
                  </div>
                </div>
                <div class="left">
                  <div class="header">نمودار هفتگی</div>
                  <div
                    v-if="
                      compareLastTwoValues(item.prices) &&
                      item.prices &&
                      JSON.parse(item.prices).length > 1
                    "
                    class="chart-container green"
                  >
                    <TrendChart
                      v-if="expandedRow === idx"
                      :datasets="[{ data: JSON.parse(item.prices) }]"
                    />
                  </div>
                  <div
                    v-if="
                      !compareLastTwoValues(item.prices) &&
                      item.prices &&
                      JSON.parse(item.prices)?.length > 1
                    "
                    class="chart-container"
                  >
                    <TrendChart
                      v-if="expandedRow === idx"
                      :datasets="[{ data: JSON.parse(item.prices) }]"
                    />
                  </div>
                  <div
                    v-if="!item.prices || JSON.parse(item.prices)?.length < 1"
                    class="chart-container"
                  ></div>
                  <div class="button">
                    <button>اطلاعات بیشتر</button>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script setup>
import { ref, onBeforeMount } from "vue";
import moment from "jalali-moment";
import UpSvg from "@/components/svgs/UpSvg.vue";
import DownSvg from "@/components/svgs/DownSvg.vue";
import LeftArrowSvg from "@/components/svgs/LeftArrowSvg.vue";
import ModalTable from "@/components/ModalTable.vue";
import axios from "axios";
import { useToast } from "vue-toastification";
import SearchSvg from "./svgs/SearchSvg.vue";
import MoneySvg from "./svgs/MoneySvg.vue";
import TypeSvg from "./svgs/TypeSvg.vue";
import WatchSvg from "./svgs/WatchSvg.vue";
import ArrowDown from "./svgs/ArrowDown.vue";
import ExpandSvg from "./svgs/ExpandSvg.vue";
import UnExpandSvg from "./svgs/UnExpandSvg.vue";
const rows = ref([]);
const toast = useToast();
const loading = ref(false);
const tableRef = ref(null);

const searchQuery = ref("");
const expandedRow = ref(null); // Track the expanded row
const EnToPr = (number) => {
  if (number === 0) {
    return 0;
  }
  if (number) {
    const persianDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    const formattedNumber = number
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const persianNumber = formattedNumber.replace(
      /\d/g,
      (digit) => persianDigits[digit]
    );

    return persianNumber;
  }
};
const EnToPrNoDate = (e) => {
  if (e) {
    const number = moment(e).format("jYYYY/jMM/jDD");
    const persianDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    const formattedNumber = number.toString();
    let persianNumber;

    if (formattedNumber.startsWith("-")) {
      persianNumber =
        "-" +
        formattedNumber
          .slice(1)
          .replace(/\d/g, (digit) => persianDigits[digit]);
    } else {
      persianNumber = formattedNumber.replace(
        /\d/g,
        (digit) => persianDigits[digit]
      );
    }
    return persianNumber;
  }
};
const EnToPrNo = (number) => {
  const persianDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  const formattedNumber = number.toString();
  let persianNumber;

  if (formattedNumber.startsWith("-")) {
    persianNumber =
      "-" +
      formattedNumber.slice(1).replace(/\d/g, (digit) => persianDigits[digit]);
  } else {
    persianNumber = formattedNumber.replace(
      /\d/g,
      (digit) => persianDigits[digit]
    );
  }

  return persianNumber;
};
const getPercent = (e) => {
  if (e) {
    const arr = JSON.parse(e);
    if (arr.length < 2) {
      console.log("Array must contain at least two elements.");
      return 0;
    }
    const lastValue = arr[arr.length - 1];
    const secondLastValue = arr[arr.length - 2];
    const number = lastValue / secondLastValue;
    const rounded = number.toFixed(2);
    if (isNaN(rounded)) {
      return 0;
    } else {
      return rounded;
    }
  }
};
function compareLastTwoValues(e) {
  if (e) {
    const arr = JSON.parse(e);
    if (arr.length < 2) {
      console.log("Array must contain at least two elements.");
      return false;
    }
    const lastValue = arr[arr.length - 1];
    const secondLastValue = arr[arr.length - 2];
    return lastValue > secondLastValue;
  }
}
const searchFunction = () => {
  // if (searchQuery.value.length >= 3) {
  //   getMaterial();
  // } else {
  getMaterial();
  // }
};
const getMaterial = async () => {
  // let search;
  // if (searchQuery.value >= 3) {
  //   search = searchQuery.value;
  // } else {
  //   search = "";
  // }
  // console.log(searchQuery.value);
  // console.log(search);
  // if (route.query.q) {
  //   searchQuery.value = route.query.q;
  // } else {
  //   searchQuery.value = "";
  // }
  try {
    loading.value = true;
    if (searchQuery.value.length > 1) {
      const { data } = await axios.get(
        `${
          process.env.VUE_APP_BASE_API
        }/api/materialsome?q=${searchQuery.value.toUpperCase()}`
      );
      rows.value = data;
    } else {
      const { data } = await axios.get(
        `${process.env.VUE_APP_BASE_API}/api/materialsome`
      );

      console.log(data);
      rows.value = data;
    }
    loading.value = false;
  } catch (err) {
    toast.error("مشکلی پیش آمده دوباره تلاش کنید.", {
      position: "top-right",
      timeout: 2000,
    });

    loading.value = false;
    console.log(err);
  }
};
const toggleRow = (index) => {
  if (expandedRow.value === index) {
    expandedRow.value = null; // Collapse the row if it's already expanded
  } else {
    expandedRow.value = index; // Expand the selected row
  }
};
onBeforeMount(() => {
  getMaterial();
});
</script>
<style scoped lang="scss">
.pricedown {
  color: #fe0000;
  svg {
    margin-top: 4px;
    width: 17px;
    height: 17px;
  }
}
.body-name {
  text-align: right;
}
.priceup {
  color: #008001;
  svg {
    margin-top: 4px;
    width: 17px;
    height: 17px;
  }
}
.table-container {
  display: flex;
  justify-content: center;
  max-height: 70vh;
  /* overflow: hidden; */
  /* overflow-y: hidden; */
  /* min-height: 200vh; */
  /* max-height: 500px; */
  .spinner-border {
    text-align: center;
    position: absolute;
    left: 50%;
  }
  th {
    &.bookmark {
      display: flex;
      position: relative;

      div {
        position: absolute;
        top: 15px;
        right: 10px;
        padding: 3px 5px;
        cursor: pointer;
        border-radius: 8px;
        background-color: #d7d7d7;
        svg {
          fill: white;
          width: 15px;
          height: 13px;
        }
      }
    }
  }
}
.mobile {
  display: none;
}
table {
  width: 1240px;
  background-color: white;
  position: relative;
  z-index: 0;
  .toolbar {
    position: absolute;
    background-color: #ecf3fd;
    width: 90%;
    margin: 0 5%;
    height: 50px;
    top: -50px;
    border-radius: 20px 20px 0 0px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right {
      width: 380px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 15px;
      font-size: 13px;
      div {
        display: flex;
        align-items: center;
        background-color: white;
        color: #8793a9;
        border-radius: 100px;
        padding: 3px 7px;
        border: 1px solid #8793a9;
        font-weight: bold;
        svg {
          margin-left: 4px;
          fill: #8793a9;
          width: 15px;
        }
        span {
          &:nth-child(2) {
            margin-left: 5px;
          }
        }
        .bold {
          margin-right: 5px;
          color: #1c66e1;
        }
      }
    }
    .left {
      position: relative;
      padding-left: 10px;
      .search {
        input {
          width: 300px;
          height: 30px;
          border: #8793a9 1px solid;
          outline: none;
          border-radius: 100px;
          padding: 0 30px;
          font-size: 12px;
        }
        svg {
          position: absolute;
          right: 6px;
          top: 5px;
          width: 20px;
          fill: #8793a9;
        }
      }
    }
  }
  .table-name {
    margin-left: 5px;
    img {
      border-radius: 100%;
      width: 24px !important;
      height: 24px !important;
    }
  }
  /* overflow: hidden; */
  border-radius: 12px !important;
  thead {
    /* background-color: white; */
    position: sticky !important;
    top: 10px;
    z-index: 10;
    overflow: hidden;
    width: 100%;
    -webkit-box-shadow: -11px 15px 37px -26px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -11px 15px 37px -26px rgba(0, 0, 0, 0.75);
    box-shadow: -11px 15px 37px -26px rgba(0, 0, 0, 0.75);
    height: 60px;
    /* background: white; */
    background-color: white;
    tr {
      border-radius: 12px;
      -webkit-box-shadow: 0px 0px 0px 1px rgba(176, 176, 176, 0.5);
      -moz-box-shadow: 0px 0px 0px 1px rgba(176, 176, 176, 0.5);
      box-shadow: 0px 0px 0px 1px rgba(176, 176, 176, 0.5);
      /* background-color: transparent;
      background: transparent; */

      padding: 0 !important;
      font-size: 15px !important;
      font-weight: bold;

      th {
        /* background: white; */
        text-align: center;
        color: #0f2851 !important;
      }
    }

    .bookmark {
      width: 38px;
      border-radius: 0 12px 12px 0;
    }
    .number {
      width: calc(38px);
    }
    .name {
      width: 200px;
      text-align: right;
    }
    .price {
      width: 119px;
    }
    .date {
      width: 140px;
    }
    .chart {
      width: 185px;
    }
    .link {
      width: 185px;
      border-radius: 12px 0 0 12px;
    }
    .change {
      width: 120px;
    }
  }
  tbody {
    .starSvg {
      div {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .link {
      color: #adadad;
      font-size: 14px;
      svg {
        width: 25px;
        fill: #adadad;
      }
    }
    tr {
      text-align: center;
      &:hover {
        .link {
          color: #1c66e1;
          svg {
            fill: #1c66e1;
          }
        }
        td {
          background: #ecf3fd !important;
          border-color: #ecf3fd;

          cursor: pointer;
        }
      }
    }
  }
  tbody {
    tr {
      height: 64px !important;
      font-weight: bold;
      font-size: 14px !important;
    }
    td {
      background-color: transparent;
      /* &:hover {
        background-color: #ecf3fc !important;
      } */
      &:first-child {
        border-radius: 0 12px 12px 0;
      }
      &:last-child {
        border-radius: 12px 0 0 12px;
      }

      line-height: 64px;
    }
  }
}
.modal-table {
  thead {
    top: 0;
    /* background-color: white; */
    /* position: relative !important; */
  }
}
.call-button {
  button {
    font-size: 11px;
  }
}
.table-body-row {
  height: 75px !important;
}
.table-main {
  position: relative;
  border-left: none !important;
  border-right: none !important;
}
.vtc {
  height: 50px;
  width: 100px;
  font-size: 12px;
}
@media only screen and (max-width: 1050px) {
  .body-name {
    text-align: right;
  }
  table {
    width: 90%;
    .toolbar {
      .right {
        font-size: 12px;
      }
    }

    thead {
      tr {
        font-size: 13px !important;
      }
    }
    tbody {
      .link {
        font-size: 12px;
        svg {
          width: 15px;
        }
      }
    }
    tbody {
      tr {
        font-size: 13px !important;
      }
    }
  }
}
@media only screen and (max-width: 770px) {
  table {
    width: 95%;
    .toolbar {
      gap: 40px;
      .right {
        font-size: 10px;
        svg {
          width: 14px;
          height: 14px;
        }
      }
      .left {
        .search {
          input {
            width: 250px;
            height: 23px;
            padding: 0 30px;
            font-size: 10px;
          }
          svg {
            position: absolute;
            right: 8px;
            top: 6px;
            width: 15px;
          }
        }
      }
    }

    thead {
      height: 50px;
      tr {
        font-size: 10px !important;
      }
    }
    tbody {
      .link {
        font-size: 10px;
        svg {
          width: 13px;
        }
      }
    }
    tbody {
      tr {
        font-size: 11px !important;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  table {
    display: none;
  }
  .table-container {
    max-height: none !important;
  }
  .mobile {
    display: inline;
  }
  table {
    width: 95%;
    .toolbar {
      gap: 0;
      flex-direction: column;
      height: 60px;
      top: -60px;
      .right {
        display: flex;
        justify-content: center;
        gap: 20px;
        font-size: 10px;
        width: 100%;
        svg {
          width: 14px;
          height: 14px;
        }
      }
      .left {
        display: flex;
        justify-content: center;
        /* align-items: center; */
        width: 90%;
        .search {
          width: 80%;
          input {
            width: 100%;
            height: 23px;
            padding: 0 30px;
            font-size: 10px;
          }
          svg {
            position: absolute;
            right: 45px;
            top: 6px;
            width: 15px;
          }
        }
      }
    }

    thead {
      height: 40px;
      tr {
        font-size: 14px !important;
      }
    }
    tbody {
      .link {
        font-size: 10px;
        svg {
          width: 13px;
        }
      }
    }
    tbody {
      tr {
        height: 50px !important;
        font-size: 14px !important;
        border-bottom: 1px solid rgba(128, 128, 128, 0.276);
        .link {
          padding-top: 10px;
          display: flex;
          flex-direction: column;
          justify-items: center;
          align-items: center;
          line-height: 20px;
        }
        td {
          line-height: 30px;
        }

        &:hover {
          .link {
            color: #adadad;
            svg {
              fill: #adadad;
            }
          }
          td {
            background: inherit !important;
            border-color: inherit;
            cursor: none;
          }
        }
      }
      .flip {
        transform: rotate(180deg);
      }
      .color-row {
        background-color: #ecf3fd;
        border-bottom: none;
        /* border-radius: 15px 0 15px 0 !important; */
        td {
          border-radius: 0 !important;
          &:first-child {
            border-radius: 0px 15px 0 0px !important;
          }
          &:last-child {
            border-radius: 15px 0 0 0 !important;
          }
        }
      }

      .details-row {
        background-color: #ecf3fd;
        transition: 0.3s;
        &.hide {
          /* display: none; */
          visibility: hidden;
          display: none;
          height: 0px;
        }
        &.show {
          visibility: visible;
        }
        td {
          border-radius: 0 !important;
          border-radius: 0 0 15px 15px !important;
        }
        .details {
          width: 90%;
          margin: 0 5%;
          display: flex;
          justify-content: space-between;
          height: 200px;
          .header {
            color: #0f2851;
          }
          .right {
            height: 100%;
            line-height: normal;
            .item {
              display: flex;
              flex-direction: column;
              text-align: right;
              margin-bottom: 8px;
              span {
                font-size: 12px;
              }
              .header {
                font-size: 14px;
                font-weight: bold;
              }
            }
          }
          .left {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            border-right: 2px solid #adadad;
            margin: 10px 0;
            padding-right: 30px;
            .chart-container {
              svg {
                width: 130px;
                height: 80px;
              }
            }
            .button {
              width: 100%;

              button {
                height: 30px;
                background-color: #1c66e1;
                border-radius: 100px;
                color: white;
                border: none;
                width: 100%;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
